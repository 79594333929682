import { useState } from "react";
import './Navbar.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons'; // Import the icon
import { faGlobe } from '@fortawesome/free-solid-svg-icons'; // Import the specific icon


export const Navbar = () => {

    const [isExpanded, setIsExpanded] = useState(false);

    // Toggle function for expanding/collapsing the sidebar
    const handleSidebarToggle = () => {
      setIsExpanded(!isExpanded);
    };

  return (
    <div className="wrapper">
    <aside id="sidebar" className={isExpanded ? 'expand' : ''}>
        <div className="d-flex">
            <button className="toggle-btn" type="button" onClick={handleSidebarToggle}>
            <FontAwesomeIcon icon={faBars} style={{ color: 'white' }} /> {/* Use the imported icon here */}
            </button>
            <div className="sidebar-logo">
                <button href="#">Dashboard</button>
            </div>
        </div>
        <ul className="sidebar-nav">
            {/* <li className="sidebar-item">
                <a href="#" className="sidebar-link">
                <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Placeholder</span>
                </a>
            </li> */}
 
            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#romano" aria-expanded="false" aria-controls="romano">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Romano Builders</span>
                </button>
                <ul id="romano" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/romanomap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                        <a href="/romanoreviews" className="sidebar-link">Google Reviews</a>
                    </li>
                </ul>
            </li>

            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#sliding" aria-expanded="false" aria-controls="sliding">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Sliding Doors</span>
                </button>
                <ul id="sliding" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/slidingmap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                    <a href="/slidingreviews" className="sidebar-link">Google Reviews</a>
                    </li>
                </ul>
            </li>

            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#rbtmap" aria-expanded="false" aria-controls="rbtmap">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Roof By Tom</span>
                </button>
                <ul id="rbtmap" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/rbtmap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                    <a href="/rbtreviews" className="sidebar-link">Google Reviews</a>
                    </li>
                </ul>
            </li>

            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#hbtmap" aria-expanded="false" aria-controls="hbtmap">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Home By Tom</span>
                </button>
                <ul id="hbtmap" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/hbtmap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                        {/* <a href="#" className="sidebar-link">Placeholder</a> */}
                    </li>
                </ul>
            </li>

            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#buildurdreammap" aria-expanded="false" aria-controls="buildurdreammap">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>BuildUrDream</span>
                </button>
                <ul id="buildurdreammap" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/buildurdreammap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                    <a href="/buildurdreamreviews" className="sidebar-link">Google Reviews</a>
                    </li>
                </ul>
            </li>

            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#tophomeroofersmap" aria-expanded="false" aria-controls="tophomeroofersmap">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Top Home Roofers</span>
                </button>
                <ul id="tophomeroofersmap" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/tophomeroofersmap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                        {/* <a href="#" className="sidebar-link">Placeholder</a> */}
                    </li>
                </ul>
            </li>

            <li className="sidebar-item">
                <button href="#" className="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#tophomebuildersmap" aria-expanded="false" aria-controls="tophomebuildersmap">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Top Home Builders</span>
                </button>
                <ul id="tophomebuildersmap" className="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li className="sidebar-item">
                        <a href="/tophomebuildersmap" className="sidebar-link">Google Map</a>
                    </li>
                    <li className="sidebar-item">
                        {/* <a href="#" className="sidebar-link">Placeholder</a> */}
                    </li>
                </ul>
            </li>

    
           
            {/* <li class="sidebar-item">
                <a href="#" class="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#multi" aria-expanded="false" aria-controls="multi">
                    <i class="lni lni-layout"></i>
                    <span>Multi Level</span>
                </a>
                <ul id="multi" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li class="sidebar-item">
                        <a href="#" class="sidebar-link collapsed" data-bs-toggle="collapse"
                            data-bs-target="#multi-two" aria-expanded="false" aria-controls="multi-two">
                            Two Links
                        </a>
                        <ul id="multi-two" class="sidebar-dropdown list-unstyled collapse">
                            <li class="sidebar-item">
                                <a href="#" class="sidebar-link">Link 1</a>
                            </li>
                            <li class="sidebar-item">
                                <a href="#" class="sidebar-link">Link 2</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li> */}
            {/* <li class="sidebar-item">
                <a href="#" class="sidebar-link collapsed has-dropdown" data-bs-toggle="collapse"
                    data-bs-target="#auth" aria-expanded="false" aria-controls="auth">
                   <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Auth</span>
                </a>
                <ul id="auth" class="sidebar-dropdown list-unstyled collapse" data-bs-parent="#sidebar">
                    <li class="sidebar-item">
                        <a href="#" class="sidebar-link">Login</a>
                    </li>
                    <li class="sidebar-item">
                        <a href="#" class="sidebar-link">Register</a>
                    </li>
                </ul>
            </li> */}
            {/* <li class="sidebar-item">
                <a href="#" class="sidebar-link">
                <FontAwesomeIcon icon={faGlobe} style={{ color: 'white', paddingRight: '8px' }} />
                    <span>Setting</span>
                </a>
            </li> */}
        </ul>
        {/* <div class="sidebar-footer">
            <a href="#" class="sidebar-link">
                <i class="lni lni-exit"></i>
                <span>Logout</span>
            </a>
        </div> */}
    </aside>
    <div className="main p-3">
        <div className="text-center">
            <h1>
                Dashboard
            </h1>
        </div>
    </div>
</div>
  );
};