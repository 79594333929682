import { images } from "../../assets/images";
import logo from '../../assets/logo.png'

export const markers = [
    {
      id: 1,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Palo Alto",
      link: "https://roofbytom.com/cities/palo-alto/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 1,
      position: { lat: 37.441883, lng: -122.143021 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 2,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Saratoga",
      link: "https://roofbytom.com/cities/saratoga/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 2,
      position: { lat: 37.268108, lng: -122.019447 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 3,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Pablo",
      link: "https://roofbytom.com/cities/san-pablo/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 3,
      position: { lat: 37.962147, lng: -122.345528 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 4,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Leandro",
      link: "https://roofbytom.com/cities/san-leandro/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 4,
      position: { lat: 37.728359, lng: -122.158607 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 5,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Carlos",
      link: "https://roofbytom.com/cities/san-carlos/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 5,
      position: { lat: 37.50456873176444, lng: -122.26198023331285 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 6,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Pleasant Hill",
      link: "https://roofbytom.com/cities/pleasant-hill/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 6,
      position: { lat: 37.65858, lng: -121.877646 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 7,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Pacifica",
      link: "https://roofbytom.com/cities/pacifica/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 7,
      position: { lat: 37.635341, lng: -122.488336 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 8,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Newark",
      link: "https://roofbytom.com/cities/newark/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 8,
      position: { lat: 37.533853, lng: -122.034779 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 9,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Moraga",
      link: "https://roofbytom.com/cities/moraga/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 9,
      position: { lat: 37.861194, lng: -122.129489 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 10,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Martinez",
      link: "https://roofbytom.com/cities/martinez/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 10,
      position: { lat: 38.011923, lng: -122.130839 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 11,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Lafayette",
      link: "https://roofbytom.com/cities/lafayette/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 11,
      position: { lat: 37.891934, lng: -122.132849 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 12,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Danville",
      link: "https://roofbytom.com/cities/danville/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 12,
      position: { lat: 37.826871, lng: -122.001424 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 13,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Concord",
      link: "https://roofbytom.com/cities/concord/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 13,
      position: { lat: 37.974336, lng: -122.028482 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 14,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Burlingame",
      link: "https://roofbytom.com/cities/burlingame/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 14,
      position: { lat: 37.580255, lng: -122.349957 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 15,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Atherton",
      link: "https://roofbytom.com/cities/atherton/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 15,
      position: { lat: 37.46221, lng: -122.198873 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 16,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In South San Francisco",
      link: "https://roofbytom.com/cities/south-san-francisco/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 16,
      position: { lat: 37.65508, lng: -122.410549 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 17,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Santa Clara",
      link: "https://roofbytom.com/cities/santa-clara/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 17,
      position: { lat: 37.351872, lng: -121.961308 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 18,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Mateo",
      link: "https://roofbytom.com/cities/san-mateo/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 18,
      position: { lat: 37.546845, lng: -122.305473 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 19,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Jose",
      link: "https://roofbytom.com/cities/san-jose/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 19,
      position: { lat: 37.332551, lng: -121.887831 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 20,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Richmond",
      link: "https://roofbytom.com/cities/richmond/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 20,
      position: { lat: 37.935648, lng: -122.346278 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 21,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Woodside",
      link: "https://roofbytom.com/cities/woodside/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 21,
      position: { lat: 37.429756, lng: -122.254037 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 22,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Woodside",
      link: "https://roofbytom.com/cities/woodside/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 21,
      position: { lat: 37.429756, lng: -122.254037 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 23,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Orinda",
      link: "https://roofbytom.com/cities/orinda/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 22,
      position: { lat: 37.882365, lng: -122.186827 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 24,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Mountain View",
      link: "https://roofbytom.com/cities/mountain-view/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 23,
      position: { lat: 37.391477, lng: -122.082206 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 25,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Mountain View",
      link: "https://roofbytom.com/cities/mountain-view/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 24,
      position: { lat: 37.391477, lng: -122.082206 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 26,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Milpitas",
      link: "https://roofbytom.com/cities/milpitas/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 26,
      position: { lat: 37.4312, lng: -121.893488 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 27,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Los Gatos",
      link: "https://roofbytom.com/cities/los-gatos/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 27,
      position: { lat: 37.221069, lng: -121.98105 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 28,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Hillsborough",
      link: "https://roofbytom.com/cities/hillsborough/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 28,
      position: { lat: 37.563811, lng: -122.367439 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 29,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Daly City",
      link: "https://roofbytom.com/cities/daly-city/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 29,
      position: { lat: 37.697606, lng: -122.463453 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 30,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Castro Valley",
      link: "https://roofbytom.com/cities/castro-valley/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 30,
      position: { lat: 37.708947, lng: -122.086267 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 31,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Berkeley",
      link: "https://roofbytom.com/cities/berkeley/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 31,
      position: { lat: 37.86477, lng: -122.274585 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 32,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Sunnyvale",
      link: "https://roofbytom.com/cities/sunnyvale/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 32,
      position: { lat: 37.368428, lng: -122.040371 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 33,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Ramon",
      link: "https://roofbytom.com/cities/san-ramon/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 33,
      position: { lat: 37.762662, lng: -121.954903 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 34,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Union City",
      link: "https://roofbytom.com/cities/union-city/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 34,
      position: { lat: 37.584962, lng: -122.031092 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 35,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In San Francisco",
      link: "https://roofbytom.com/cities/san-francisco/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 35,
      position: { lat: 37.773837, lng: -122.420131 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 36,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Redwood City",
      link: "https://roofbytom.com/cities/redwood-city/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 36,
      position: { lat: 37.48348, lng: -122.227913 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 37,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Pleasanton",
      link: "https://roofbytom.com/cities/pleasanton/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 37,
      position: { lat: 37.657587, lng: -121.876418 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 38,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Oakland",
      link: "https://roofbytom.com/cities/oakland/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 38,
      position: { lat: 37.802712, lng: -122.272458 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 39,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Morgan Hill",
      link: "https://roofbytom.com/cities/morgan-hill/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 39,
      position: { lat: 37.125578, lng: -121.64777 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 40,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Menlo Park",
      link: "https://roofbytom.com/cities/menlo-park/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 40,
      position: { lat: 37.455193, lng: -122.175596 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 41,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Los Altos",
      link: "https://roofbytom.com/cities/los-altos-2/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 41,
      position: { lat: 37.381657, lng: -122.112075 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 42,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Fremont",
      link: "https://roofbytom.com/cities/fremont/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 42,
      position: { lat: 37.549504, lng: -121.982925 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 43,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Cupertino",
      link: "https://roofbytom.com/cities/cupertino/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 43,
      position: { lat: 37.319583, lng: -122.02507 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 44,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Campbell",
      link: "https://roofbytom.com/cities/campbell/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 44,
      position: { lat: 37.288512, lng: -121.958968 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },
    {
      id: 45,
      name: "Roof By Tom",
      sliderImageOne: images.roof1,
      sliderImageTwo: images.roof2,
      description: "Roof Repair And Replacement In Belmont",
      link: "https://roofbytom.com/cities/belmont/",
      address: "San Jose, CA",
      placeId: "ChIJj4veledhhYARzR63xm5MzMY",
      reviewId: 45,
      position: { lat: 37.519379, lng: -122.274752 },
      contractorName: "Roof By Tom.",
      contractorPicture: logo,
      phoneNumber: "(925) 281-5396",
    },

  ];