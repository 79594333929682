import { images } from "../../assets/projects/images";
import logo from '../../assets/logo.png';


export const markers = [
    {
      id: 1,
      name: "Crestmoor - San Bruno",
      sliderImageOne: images.crestmoor1,
      sliderImageTwo: images.crestmoor2,
      description: "Crestmoor - San Bruno Full House Remodel",
      link: "https://buildurdream.com/projects/crestmoor-san-bruno/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 1,
      position: { lat: 37.624769, lng: -122.430632 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 2,
      name: "23rd Ave",
      sliderImageOne: images.rd231,
      sliderImageTwo: images.rd232,
      description: "23rd Ave - San Francisco Full House Remodel",
      link: "https://buildurdream.com/projects/23rd-ave-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 3,
      position: { lat: 37.783333, lng: -122.482838 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 3,
      name: "39th Ave",
      sliderImageOne: images.th391,
      sliderImageTwo: images.th392,
      description: "39th Ave - San Francisco Full House Remodel",
      link: "https://buildurdream.com/projects/39th-ave-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 4,
      position: { lat: 37.740141, lng: -122.497001 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 4,
      name: "Truman - Los Altos",
      sliderImageOne: images.truman1,
      sliderImageTwo: images.truman2,
      description: "Truman - Los Altos Full House Remodel",
      link: "https://buildurdream.com/projects/truman-los-altos/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 5,
      position: { lat: 37.356781, lng: -122.06872 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 5,
      name: "Astoria - Sunnyvale",
      sliderImageOne: images.astoria1,
      sliderImageTwo: images.astoria2,
      description: "Astoria - Sunnyvale Full House Remodel",
      link: "https://buildurdream.com/projects/astoria-sunny-valley/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 6,
      position: { lat: 37.350388, lng: -122.056238 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 6,
      name: "Noe - San Francisco",
      sliderImageOne: images.noe1,
      sliderImageTwo: images.noe2,
      description: "Noe - San Francisco Full House Remodel",
      link: "https://buildurdream.com/projects/noe-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 7,
      position: { lat: 37.751597, lng: -122.447749 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 7,
      name: "46th Ave - San Francisco",
      sliderImageOne: images.th461,
      sliderImageTwo: images.th462,
      description: "46th Ave - San Francisco Full House Remodel",
      link: "https://buildurdream.com/projects/46th-ave-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 7,
      position: { lat: 37.743645, lng: -122.504769 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 8,
      name: "Bridgeview - San Francisco",
      sliderImageOne: images.bridge1,
      sliderImageTwo: images.bridge2,
      description: "Bridgeview - San Francisco Full House Remodel",
      link: "https://buildurdream.com/projects/bridgeview-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 7,
      position: { lat: 37.769886, lng: -122.387706 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 9,
      name: "Birch - Hayward",
      sliderImageOne: images.hayward1,
      sliderImageTwo: images.hayward2,
      description: "Birch - Hayward Full House Remodel",
      link: "https://buildurdream.com/projects/birch-hayward/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 7,
      position: { lat: 37.630507, lng: -122.038187 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 10,
      name: "Dorchester - San Mateo",
      sliderImageOne: images.dort1,
      sliderImageTwo: images.dort2,
      description: "Dorchester - San Mateo Bathroom Remodel",
      link: "https://buildurdream.com/projects/dorchester-san-mateo/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 7,
      position: { lat: 37.566179, lng: -122.343452 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 11,
      name: "Politzer - Menlo Park",
      sliderImageOne: images.pol1,
      sliderImageTwo: images.pol2,
      description: "Politzer - Menlo Park Full House Remodel",
      link: "https://buildurdream.com/projects/politzer-menlo-park/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 8,
      position: { lat: 37.441628, lng: -122.196993 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 12,
      name: "Malta Dr. - San Francisco",
      sliderImageOne: images.malta1,
      sliderImageTwo: images.malta2,
      description: "Malta Dr. - San Francisco Full House Remodel",
      link: "https://buildurdream.com/projects/malta-dr-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 8,
      position: { lat: 37.735325, lng: -122.444069 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 13,
      name: "Kitchen Remodeling - San Francisco",
      sliderImageOne: images.ksf1,
      sliderImageTwo: images.ksf2,
      description: "Kitchen Remodeling - San Francisco",
      link: "https://buildurdream.com/projects/kitchen-remodeling-san-francisco/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 9,
      position: { lat: 37.779259, lng: -122.419329 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },
    {
      id: 14,
      name: "Shepard Way - Redwood City",
      sliderImageOne: images.shepard1,
      sliderImageTwo: images.shepard2,
      description: "Kitchen Remodeling Shepard Way - Redwood City",
      link: "https://buildurdream.com/projects/shepard-way-redwood-city/",
      address: "San Francisco, CA",
      placeId: "ChIJv2yYk2Z3j4ARmRmp5GrJP2Q",
      reviewId: 9,
      position: { lat: 37.461734, lng: -122.258718 },
      contractorName: "Build Ur Dream",
      contractorPicture: logo,
      phoneNumber: "(650) 651-7282",
    },

  ];