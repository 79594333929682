import crestmoor1 from './san-bruno-1/crestmoor-1.jpg'
import crestmoor2 from './san-bruno-1/crestmoor-2.jpg'
import rd231 from './23rd-ave/23rd-1.jpg'
import rd232 from './23rd-ave/23rd-2.jpg'
import th391 from './39th-ave/39th-1.jpg'
import th392 from './39th-ave/39th-2.jpg'
import truman1 from './truman/truman-1.jpg'
import truman2 from './truman/truman-2.jpg'
import astoria1 from './astoria/astoria-1.jpg'
import astoria2 from './astoria/astoria-2.jpg'
import noe1 from './noe/noe-1.jpg'
import noe2 from './noe/noe-2.jpg'
import th461 from './46th-ave/46th-1.jpg'
import th462 from './46th-ave/46th-2.jpg'
import bridge1 from './bridgeview/bridgeview-1.jpg'
import bridge2 from './bridgeview/bridgeview-2.jpg'
import hayward1 from './hayward/birch-1.jpg'
import hayward2 from './hayward/birch-2.jpg'
import dort1 from './dorchester/dorchester-1.jpg'
import dort2 from './dorchester/dorchester-2.jpg'
import pol1 from './politzer/politzer-1.jpg'
import pol2 from './politzer/politzer-2.jpg'
import malta1 from './malta/malta-dr-1.jpg'
import malta2 from './malta/malta-dr-2.jpg'
import ksf1 from './kitche-sanfran/kitchen-remodeling-san-francisco-01.webp'
import ksf2 from './kitche-sanfran/kitchen-remodeling-san-francisco-05.webp'
import shepard1 from './shepard/shepard-way-redwood-city-02.webp'
import shepard2 from './shepard/shepard-way-redwood-city-05.webp'

export const images = { 
    crestmoor1,
    crestmoor2,
    rd231,
    rd232,
    th391,
    th392,
    truman1,
    truman2,
    astoria1,
    astoria2,
    noe1,
    noe2,
    th461,
    th462,
    bridge1,
    bridge2,
    hayward1,
    hayward2,
    dort1,
    dort2,
    pol1,
    pol2,
    malta1,
    malta2,
    ksf1,
    ksf2,
    shepard1,
    shepard2
}